<template>
 <base-page>
    <titlu-pagina Titlu="Upload semnatura" />

    <el-row style="margin-top:15px;">
        <el-col style="margin-bottom: 20px;" :span="8" :offset="8">
            <el-upload class="full-width upload-image" 
                drag="drag" 
                :action="Constants.ServerUrl + 'inspectii_finale/upload_semnatura' " 
                :show-file-list="false" :on-success="handleUploadSuccess" multiple="multiple">
                <i class="el-icon-upload">
                    <div class="el-upload__text full-width">Drop image here or click to upload</div>
                </i>
            </el-upload>
            <el-button type="success" @click="save">Salveaza</el-button>
        </el-col>
    </el-row>
    <el-row>
        <el-col style="color: white;" :span="8" :offset="8">Nume Inspector</el-col>
    </el-row>
    <el-row>
        <el-col :span="8" :offset="8">
            <el-input style="width:265px;" v-model="Nume"></el-input>
            <el-button type="success" @click="modifica_nume">Modifica</el-button>
        </el-col>
    </el-row>
       
</base-page>
        
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import TitluPagina from '@/widgets/TitluPagina';
    import Constants from '@/backend/Constants'

    export default {
        name: "Inspectie_finala_settings",
        extends: BasePage,
        components:{
            'base-page': BasePage,           
            'titlu-pagina': TitluPagina,
        },
        data () {
            return {
                showPopup: true,
                BASE_URL: settings.BASE_URL,
                Constants: Constants,
                Continut: { },
                Nume: ''                                                                   
            }
        },
        methods: {

            async get_info(){
                var response = await this.post('inspectii_finale/get_info')
                this.Nume = response.Result
            },

            handleUploadSuccess(response){            
                if( response.HasError ){
                    this.$message(response.Error);
                }else{
                    this.Continut = { Id:-1, IsImage: response.IsImage, Filename: response.FileName, Url: response.Url } ;
                }
            },

            save: async function() {                                
                await this.post("inspectii_finale/save_semnatura", { semnatura: this.Continut } );                                 
            },

            async modifica_nume(){
                var response = await this.post('inspectii_finale/modifica_nume', {Nume: this.Nume})
                this.$message({type: "success", message: 'Nume modifcat cu succes!'})
                this.get_info();
                
            }
        },
        mounted() {
            this.get_info()
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>